@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--paper-red-50);
}

html,
body {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  margin-top: 40px;
  border: 0;
}

#root {
  min-height: 100%;
}

.row {
  display: flex;
  min-height: 100%;
  /* height: 94vh; */
  align-items: center;
  justify-content: center;
}

.select_column {
  width: 50%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
  transition: width 0.5s;
  padding: 1rem;
  /* border: 2px solid black; */
}

.select_column:hover {
  width: 100%;
}

/* .select_column:link {
  color: black;
}

.select_column:visited {
  color: black; 
}

.select_column:link {
  text-decoration: none;
} */

.host {
  background: black;
  color: var(--paper-yellow-a700);
  /* border-right: 1px solid black; */
}

.host:hover {
  background: black;
  color: var(--paper-yellow-a700);
  /* border-right: 1px solid black; */
}

.join {
  background: var(--paper-yellow-a700);
  color: black;
  /* border-left: 1px solid black; */
}

.join:hover {
  background: var(--paper-yellow-a700);
  color: black;
  /* border-left: 1px solid black; */
}

.emoji-mart {
  position: absolute;
  top: 23%;
}
.emoji-mart-scroll {
  border-bottom: 0;
  margin-bottom: 6px;
}

.emoji-mart-scroll + .emoji-mart-bar {
  display: none;
}

.emoji {
  font-weight: 400;
  font-family: apple color emoji, segoe ui emoji, noto color emoji,
    android emoji, emojisymbols, emojione mozilla, twemoji mozilla,
    segoe ui symbol;
  font-size: 1.2em;
}

.emoji-button {
  border: none;
}

.emoji-button:hover {
  border: none;
}

.chat_box {
  height: 57vh;
  overflow-y: auto;
}

.chat_user_name {
  /* font-size: 0.7em; */
  margin: 0 17px 2px 0;
  color: grey;
}

.self {
  float: right;
}

.player_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.left_tile_custom {
  flex-direction: column;
  justify-content: center;
}

.avatar {
  width: 30px;
  text-align: center;
  height: 30px;
  background-color: green;
  border-radius: 50%;
  font-size: 1.2em;
  color: white;
  text-transform: uppercase;
  margin: 5px;
  display: inline-block;
}

.avatar_container {
  /* display: flex; */
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.info_box {
  margin-top: 2%;
  /* margin-right: 2%; */
}

.changeVideo_divider {
  margin: 0.2rem 0 0.8rem 0;
}

.homepage_left_hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepage_left_hero h1 {
  font-size: 3em;
}

.homepage_explainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  /* transition: width 0.5s; */
  /* border: 2px solid black; */
}

.laptop_svg {
  height: 100px;
  margin: 12px;
}

/* .ion-ios-paperplane{
  font-size: xx-large; 
  align-items: normal;
  height: 1em;
} */

/* .ion-ios-paperplane::before{
    content: "\f474";
    transform: translate(0, -0.2vw);
} */

.sync_svg {
  height: 100px;

  -webkit-animation-name: ckw;

          animation-name: ckw;
  -webkit-animation-duration: 2000ms;
          animation-duration: 2000ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  transform-origin: 50% 50%;
  display: inline-block;
}
@-webkit-keyframes ckw {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes ckw {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.hero_video {
  border: 2px solid black;
  border-radius: 5px;
}

.full_height {
  min-height: 100vh;
}

.full_height .tile {
  justify-content: center;
}

.grid_row {
  min-height: 100vh;
  display: grid;
  /* grid-template-columns: repeat(auto-fit, 1fr); */
  align-items: center;
  grid-auto-rows: 1fr;
  align-items: stretch;
}

.grid_row .content_party {
  margin: 0px !important;
  padding: 0px 40px 40px 0px;
  /* border-right: 1px solid black;
  border-bottom: 1px solid black; */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: var(--paper-grey-300);
}

.grid_row .content_copy {
  margin: 0px !important;
  padding: 0px 40px 40px 0px;
  /* border-left: 1px solid black;
  border-bottom: 1px solid black; */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: var(--paper-blue-700);
}

.grid_row .content_sync {
  margin: 0px !important;
  padding: 0px 40px 40px 0px;
  /* border-right: 1px solid black;
  border-top: 1px solid black; */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: var(--paper-yellow-a700);
}

.grid_row .content_chat {
  margin: 0px !important;
  padding: 0px 40px 40px 0px;
  /* border-left: 1px solid black;
  border-top: 1px solid black; */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: var(--paper-red-a400);
}

.expliner_img {
  display: block;
  width: 100%;
  box-shadow: rgba(84, 70, 35, 0.15) 0px 2px 8px,
    rgba(84, 70, 35, 0.15) 0px 1px 3px;
  border-radius: 4px;
  margin-bottom: 18px;
  width: 450px;
  height: 200px;
}

.floating {
  -webkit-animation-name: floating;
          animation-name: floating;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
  height: 100px;
  margin: 5px;
}

@-webkit-keyframes floating {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 15px);
  }
  to {
    transform: translate(0, -0px);
  }
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 15px);
  }
  to {
    transform: translate(0, -0px);
  }
}

@media all and (min-width: 700px) {
  .grid_row {
    grid-template-columns: 1fr 1fr;
  }
}

.grid_row h4 {
  color: black;
  margin-top: 0.8em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-weight: bold;
}

.not-selectable {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.navbar-item img {
  max-height: 2.5rem;
  max-width: 3.5rem;
  cursor: pointer;
}

.navbar-item a {
  max-height: 2.5rem;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

img {
  height: 100%;
  max-width: 100%;
}

.navbar-item {
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 1.5em;
  color: white;
  font-weight: bold;
}

.navbar {
  background-color: #212121;
  min-height: 3.25rem;
  position: relative;
  z-index: 30;
}

.has-background-primary {
  background-color: var(--paper-red-a400) !important;
}

.button.is-primary {
  background-color: var(--paper-red-a400);
  border-color: transparent;
  color: #fff;
  font-weight: bold;
}

.button.is-primary:hover {
  background-color: var(--paper-red-a200);
  border-color: transparent;
  color: #fff;
  font-weight: bold;
}

input[type="text"]:focus,
textarea:focus,
input[type="url"]:focus {
  box-shadow: 0 0 3px var(--paper-red-a400);
  border: 1px solid var(--paper-red-a400);
}

/* .section {
  padding: 2.5rem 1rem;
} */

/* Scrollbar - start */
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Scrollbar - end */

/* Profile card - start*/
.snip {
  box-shadow: none !important;
  color: #141414;
  display: inline-block;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  line-height: 1.4em;
  margin: 10px;
  max-width: 315px;
  min-width: 230px;
  position: relative;
  text-align: left;
  width: 100%;
}

.snip * {
  box-sizing: border-box;
}

.snip .profile-image img {
  border-radius: 5px;
  max-width: 100%;
  height: 120px;
  vertical-align: top;
  float: left;
  margin-right: 10px;
}

.snip figcaption {
  background-color: #333333;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  margin-top: 15px;
  padding: 25px;
  position: relative;
  width: 100%;
}

.snip figcaption:after {
  border-color: transparent transparent #333333 transparent;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  bottom: 100%;
  content: "";
  height: 0;
  left: 32px;
  position: absolute;
  width: 0;
}

.snip h3,
.snip h4,
.snip p {
  margin: 0 0 5px;
}

.snip h3 {
  font-weight: 600;
  font-size: 1.2em;
  font-family: "Montserrat", Arial, sans-serif;
}

.snip h4 {
  color: #8c8c8c;
  font-weight: 400;
  letter-spacing: 2px;
}

.snip p {
  font-size: 0.9em;
  letter-spacing: 1px;
  opacity: 0.9;
}

.snip .icons {
  padding: 87px 70px 0px 70px;
}

.snip i {
  color: #ffffff;
  display: inline-block;
  font-size: 33px;
  font-weight: normal;
  opacity: 0.75;
  padding: 0px 2px;
}

.snip i:hover {
  opacity: 1;
  transition: all 0.35s ease;
}

/* Profile card - End */

.about_right {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  /* height: 94vh; */
  align-items: flex-end;
  justify-content: center;
  background-color: #212121;
}

.about_left {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  /* height: 94vh; */
  align-items: flex-start;
  justify-content: center;
  background-color: #212121;
}

.bmac_link {
  padding-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.bmac {
  height: 51px !important;
  width: 170px !important;
  max-width: 170px !important;
  border-radius: 3px;
}

.change-video {
  padding: 1rem 1rem 0 0;
}

.chat-window {
  padding-left: 0.8rem;
  width: 34%;
}

.container {
  max-width: 98% !important;
}

.product_hunt_button {
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
}


html:not(.style-scope) {
  --google-red-100: #f4c7c3;
  --google-red-300: #e67c73;
  --google-red-500: #db4437;
  --google-red-700: #c53929;
  --google-blue-100: #c6dafc;
  --google-blue-300: #7baaf7;
  --google-blue-500: #4285f4;
  --google-blue-700: #3367d6;
  --google-green-100: #b7e1cd;
  --google-green-300: #57bb8a;
  --google-green-500: #0f9d58;
  --google-green-700: #0b8043;
  --google-yellow-100: #fce8b2;
  --google-yellow-300: #f7cb4d;
  --google-yellow-500: #f4b400;
  --google-yellow-700: #f09300;
  --google-grey-100: #f5f5f5;
  --google-grey-300: #e0e0e0;
  --google-grey-500: #9e9e9e;
  --google-grey-700: #616161;
  --paper-red-50: #ffebee;
  --paper-red-100: #ffcdd2;
  --paper-red-200: #ef9a9a;
  --paper-red-300: #e57373;
  --paper-red-400: #ef5350;
  --paper-red-500: #f44336;
  --paper-red-600: #e53935;
  --paper-red-700: #d32f2f;
  --paper-red-800: #c62828;
  --paper-red-900: #b71c1c;
  --paper-red-a100: #ff8a80;
  --paper-red-a200: #ff5252;
  --paper-red-a400: #ff1744;
  --paper-red-a700: #d50000;
  --paper-pink-50: #fce4ec;
  --paper-pink-100: #f8bbd0;
  --paper-pink-200: #f48fb1;
  --paper-pink-300: #f06292;
  --paper-pink-400: #ec407a;
  --paper-pink-500: #e91e63;
  --paper-pink-600: #d81b60;
  --paper-pink-700: #c2185b;
  --paper-pink-800: #ad1457;
  --paper-pink-900: #880e4f;
  --paper-pink-a100: #ff80ab;
  --paper-pink-a200: #ff4081;
  --paper-pink-a400: #f50057;
  --paper-pink-a700: #c51162;
  --paper-purple-50: #f3e5f5;
  --paper-purple-100: #e1bee7;
  --paper-purple-200: #ce93d8;
  --paper-purple-300: #ba68c8;
  --paper-purple-400: #ab47bc;
  --paper-purple-500: #9c27b0;
  --paper-purple-600: #8e24aa;
  --paper-purple-700: #7b1fa2;
  --paper-purple-800: #6a1b9a;
  --paper-purple-900: #4a148c;
  --paper-purple-a100: #ea80fc;
  --paper-purple-a200: #e040fb;
  --paper-purple-a400: #d500f9;
  --paper-purple-a700: #aa00ff;
  --paper-deep-purple-50: #ede7f6;
  --paper-deep-purple-100: #d1c4e9;
  --paper-deep-purple-200: #b39ddb;
  --paper-deep-purple-300: #9575cd;
  --paper-deep-purple-400: #7e57c2;
  --paper-deep-purple-500: #673ab7;
  --paper-deep-purple-600: #5e35b1;
  --paper-deep-purple-700: #512da8;
  --paper-deep-purple-800: #4527a0;
  --paper-deep-purple-900: #311b92;
  --paper-deep-purple-a100: #b388ff;
  --paper-deep-purple-a200: #7c4dff;
  --paper-deep-purple-a400: #651fff;
  --paper-deep-purple-a700: #6200ea;
  --paper-indigo-50: #e8eaf6;
  --paper-indigo-100: #c5cae9;
  --paper-indigo-200: #9fa8da;
  --paper-indigo-300: #7986cb;
  --paper-indigo-400: #5c6bc0;
  --paper-indigo-500: #3f51b5;
  --paper-indigo-600: #3949ab;
  --paper-indigo-700: #303f9f;
  --paper-indigo-800: #283593;
  --paper-indigo-900: #1a237e;
  --paper-indigo-a100: #8c9eff;
  --paper-indigo-a200: #536dfe;
  --paper-indigo-a400: #3d5afe;
  --paper-indigo-a700: #304ffe;
  --paper-blue-50: #e3f2fd;
  --paper-blue-100: #bbdefb;
  --paper-blue-200: #90caf9;
  --paper-blue-300: #64b5f6;
  --paper-blue-400: #42a5f5;
  --paper-blue-500: #2196f3;
  --paper-blue-600: #1e88e5;
  --paper-blue-700: #1976d2;
  --paper-blue-800: #1565c0;
  --paper-blue-900: #0d47a1;
  --paper-blue-a100: #82b1ff;
  --paper-blue-a200: #448aff;
  --paper-blue-a400: #2979ff;
  --paper-blue-a700: #2962ff;
  --paper-light-blue-50: #e1f5fe;
  --paper-light-blue-100: #b3e5fc;
  --paper-light-blue-200: #81d4fa;
  --paper-light-blue-300: #4fc3f7;
  --paper-light-blue-400: #29b6f6;
  --paper-light-blue-500: #03a9f4;
  --paper-light-blue-600: #039be5;
  --paper-light-blue-700: #0288d1;
  --paper-light-blue-800: #0277bd;
  --paper-light-blue-900: #01579b;
  --paper-light-blue-a100: #80d8ff;
  --paper-light-blue-a200: #40c4ff;
  --paper-light-blue-a400: #00b0ff;
  --paper-light-blue-a700: #0091ea;
  --paper-cyan-50: #e0f7fa;
  --paper-cyan-100: #b2ebf2;
  --paper-cyan-200: #80deea;
  --paper-cyan-300: #4dd0e1;
  --paper-cyan-400: #26c6da;
  --paper-cyan-500: #00bcd4;
  --paper-cyan-600: #00acc1;
  --paper-cyan-700: #0097a7;
  --paper-cyan-800: #00838f;
  --paper-cyan-900: #006064;
  --paper-cyan-a100: #84ffff;
  --paper-cyan-a200: #18ffff;
  --paper-cyan-a400: #00e5ff;
  --paper-cyan-a700: #00b8d4;
  --paper-teal-50: #e0f2f1;
  --paper-teal-100: #b2dfdb;
  --paper-teal-200: #80cbc4;
  --paper-teal-300: #4db6ac;
  --paper-teal-400: #26a69a;
  --paper-teal-500: #009688;
  --paper-teal-600: #00897b;
  --paper-teal-700: #00796b;
  --paper-teal-800: #00695c;
  --paper-teal-900: #004d40;
  --paper-teal-a100: #a7ffeb;
  --paper-teal-a200: #64ffda;
  --paper-teal-a400: #1de9b6;
  --paper-teal-a700: #00bfa5;
  --paper-green-50: #e8f5e9;
  --paper-green-100: #c8e6c9;
  --paper-green-200: #a5d6a7;
  --paper-green-300: #81c784;
  --paper-green-400: #66bb6a;
  --paper-green-500: #4caf50;
  --paper-green-600: #43a047;
  --paper-green-700: #388e3c;
  --paper-green-800: #2e7d32;
  --paper-green-900: #1b5e20;
  --paper-green-a100: #b9f6ca;
  --paper-green-a200: #69f0ae;
  --paper-green-a400: #00e676;
  --paper-green-a700: #00c853;
  --paper-light-green-50: #f1f8e9;
  --paper-light-green-100: #dcedc8;
  --paper-light-green-200: #c5e1a5;
  --paper-light-green-300: #aed581;
  --paper-light-green-400: #9ccc65;
  --paper-light-green-500: #8bc34a;
  --paper-light-green-600: #7cb342;
  --paper-light-green-700: #689f38;
  --paper-light-green-800: #558b2f;
  --paper-light-green-900: #33691e;
  --paper-light-green-a100: #ccff90;
  --paper-light-green-a200: #b2ff59;
  --paper-light-green-a400: #76ff03;
  --paper-light-green-a700: #64dd17;
  --paper-lime-50: #f9fbe7;
  --paper-lime-100: #f0f4c3;
  --paper-lime-200: #e6ee9c;
  --paper-lime-300: #dce775;
  --paper-lime-400: #d4e157;
  --paper-lime-500: #cddc39;
  --paper-lime-600: #c0ca33;
  --paper-lime-700: #afb42b;
  --paper-lime-800: #9e9d24;
  --paper-lime-900: #827717;
  --paper-lime-a100: #f4ff81;
  --paper-lime-a200: #eeff41;
  --paper-lime-a400: #c6ff00;
  --paper-lime-a700: #aeea00;
  --paper-yellow-50: #fffde7;
  --paper-yellow-100: #fff9c4;
  --paper-yellow-200: #fff59d;
  --paper-yellow-300: #fff176;
  --paper-yellow-400: #ffee58;
  --paper-yellow-500: #ffeb3b;
  --paper-yellow-600: #fdd835;
  --paper-yellow-700: #fbc02d;
  --paper-yellow-800: #f9a825;
  --paper-yellow-900: #f57f17;
  --paper-yellow-a100: #ffff8d;
  --paper-yellow-a200: #ffff00;
  --paper-yellow-a400: #ffea00;
  --paper-yellow-a700: #ffd600;
  --paper-amber-50: #fff8e1;
  --paper-amber-100: #ffecb3;
  --paper-amber-200: #ffe082;
  --paper-amber-300: #ffd54f;
  --paper-amber-400: #ffca28;
  --paper-amber-500: #ffc107;
  --paper-amber-600: #ffb300;
  --paper-amber-700: #ffa000;
  --paper-amber-800: #ff8f00;
  --paper-amber-900: #ff6f00;
  --paper-amber-a100: #ffe57f;
  --paper-amber-a200: #ffd740;
  --paper-amber-a400: #ffc400;
  --paper-amber-a700: #ffab00;
  --paper-orange-50: #fff3e0;
  --paper-orange-100: #ffe0b2;
  --paper-orange-200: #ffcc80;
  --paper-orange-300: #ffb74d;
  --paper-orange-400: #ffa726;
  --paper-orange-500: #ff9800;
  --paper-orange-600: #fb8c00;
  --paper-orange-700: #f57c00;
  --paper-orange-800: #ef6c00;
  --paper-orange-900: #e65100;
  --paper-orange-a100: #ffd180;
  --paper-orange-a200: #ffab40;
  --paper-orange-a400: #ff9100;
  --paper-orange-a700: #ff6500;
  --paper-deep-orange-50: #fbe9e7;
  --paper-deep-orange-100: #ffccbc;
  --paper-deep-orange-200: #ffab91;
  --paper-deep-orange-300: #ff8a65;
  --paper-deep-orange-400: #ff7043;
  --paper-deep-orange-500: #ff5722;
  --paper-deep-orange-600: #f4511e;
  --paper-deep-orange-700: #e64a19;
  --paper-deep-orange-800: #d84315;
  --paper-deep-orange-900: #bf360c;
  --paper-deep-orange-a100: #ff9e80;
  --paper-deep-orange-a200: #ff6e40;
  --paper-deep-orange-a400: #ff3d00;
  --paper-deep-orange-a700: #dd2c00;
  --paper-brown-50: #efebe9;
  --paper-brown-100: #d7ccc8;
  --paper-brown-200: #bcaaa4;
  --paper-brown-300: #a1887f;
  --paper-brown-400: #8d6e63;
  --paper-brown-500: #795548;
  --paper-brown-600: #6d4c41;
  --paper-brown-700: #5d4037;
  --paper-brown-800: #4e342e;
  --paper-brown-900: #3e2723;
  --paper-grey-50: #fafafa;
  --paper-grey-100: #f5f5f5;
  --paper-grey-200: #eeeeee;
  --paper-grey-300: #e0e0e0;
  --paper-grey-400: #bdbdbd;
  --paper-grey-500: #9e9e9e;
  --paper-grey-600: #757575;
  --paper-grey-700: #616161;
  --paper-grey-800: #424242;
  --paper-grey-900: #212121;
  --paper-blue-grey-50: #eceff1;
  --paper-blue-grey-100: #cfd8dc;
  --paper-blue-grey-200: #b0bec5;
  --paper-blue-grey-300: #90a4ae;
  --paper-blue-grey-400: #78909c;
  --paper-blue-grey-500: #607d8b;
  --paper-blue-grey-600: #546e7a;
  --paper-blue-grey-700: #455a64;
  --paper-blue-grey-800: #37474f;
  --paper-blue-grey-900: #263238;
  --dark-divider-opacity: 0.12;
  --dark-disabled-opacity: 0.38;
  --dark-secondary-opacity: 0.54;
  --dark-primary-opacity: 0.87;
  --light-divider-opacity: 0.12;
  --light-disabled-opacity: 0.3;
  --light-secondary-opacity: 0.7;
  --light-primary-opacity: 1;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

